<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h6">
          {{ $t("labels.set_license_permission") }}
        </span>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancel">
          {{ $t("labels.cancel") }}
        </v-btn>
        <v-btn color="green darken-1" text @click="confirm">
          {{ $t("labels.confirm") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row
          no-gutters
          v-for="(sortKey, index) in sortKeys"
          :key="`sk_${sortKey}_${index}`"
        >
          <v-col
            cols="12"
            md="4"
            v-for="pms in groupBySort[sortKey]"
            :key="`im_${pms.id}`"
          >
            <v-checkbox
              dense
              hide-details
              class="c-input-small"
              v-model="selectedPermissions"
              :value="pms.id"
              :label="pms.description"
              multiple
            ></v-checkbox>
          </v-col>
          <v-col cols="12" v-if="index < sortKeys.length - 1">
            <v-divider class="my-3"></v-divider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {} from "@/libs/const";
export default {
  components: {},
  name: "PricePermission",
  props: {
    itemDetail: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    item: {},
    permissions: [],
    selectedPermissions: [],
  }),
  computed: {
    groupBySort() {
      const group = {};
      for (let i = 0; i < this.permissions.length; i++) {
        const permission = this.permissions[i];
        if (!group[permission.sort]) {
          group[permission.sort] = [];
        }
        group[permission.sort].push(permission);
      }

      return group;
    },
    sortKeys() {
      return Object.keys(this.groupBySort);
    },
  },
  created() {},
  mounted() {
    this.getSelectedPermissions();
    this.getPermissions();
  },
  methods: {
    async getPermissions() {
      const { data } = await httpClient.post(
        "/license/v1/get-permission-by-type",
        {
          type: this.itemDetail.type,
        }
      );
      this.permissions = [...data];
    },
    async getSelectedPermissions() {
      const { data } = await httpClient.post(
        "/license/v1/get-license-permission",
        {
          id_license: this.itemDetail.id,
        }
      );
      this.selectedPermissions = [...data].map((e) => e.id_permission);
    },
    cancel() {
      this.$emit("cancel");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    async confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/license/v1/permission-save", {
          id_license: this.itemDetail.id,
          id_permissions: this.selectedPermissions,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.refreshData();
        this.cancel();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>
